import {ref} from 'vue'

export function useCreateFormData () {
    const formData = ref({
        company_id:'',
        contact_profile_id: null,
        payment_date:'',
        voucher_no:'',
        payment_reference:'',
        payment_reference_date:'',
        account_head_id:null,
        mop_account_head_id:null,
        ipd_register_id:'',
        description:'',
        transactions: []
    })

    const salesFormData = ref({
        company_id:'',
        contact_profile_id: null,
        receipt_date:'',
        voucher_no:'',
        receipt_reference:'',
        receipt_reference_date:'',
        account_head_id:null,
        vat_challan_account_head_id:null,
        ait_challan_account_head_id:null,
        discount_account_head_id:null,
        ipd_register_id:'',
        account_receivable_id:null,
        total_paid_amount:null,
        mop_account_head_id:null,
        description:'',
        transactions: []
    })

    const prepareFormData = (bills, type) => {
        let fd = new FormData()
        formData.value.mop_account_head_id = formData.value.account_head_id;
        Object.keys(formData.value).forEach(key => {
            if(!['transactions'].includes(key)) {
                fd.append(key, formData.value[key])
            }
        })

        let filteredBills =  bills.filter(bill => bill.payment != 0)
        let modifiedBills = filteredBills.map(bill => {
            return {
                [`${type}_master_id`]: bill.id,
                payment: bill.payment
            }
        })

        fd.append('transactions', JSON.stringify(modifiedBills))
        return fd
    }
    const prepareSalesFormData = (bills, type) => {
        let fd = new FormData()
        salesFormData.value.mop_account_head_id = salesFormData.value.account_head_id;
        Object.keys(salesFormData.value).forEach(key => {
            if(!['transactions'].includes(key)) {
                fd.append(key, salesFormData.value[key])
            }
        })

        let filteredBills =  bills.filter(bill => bill.payment != 0)
        let modifiedBills = filteredBills.map(bill => {
            console.log(bill, 'bill')
            return {
                [`${type}_master_id`]: bill.id,
                payment: bill.payment,
                vat_challan_amount: bill.vat_challan_amount ? bill.vat_challan_amount : 0,
                ait_challan_amount: bill.ait_challan_amount ? bill.ait_challan_amount : 0,
                discount_amount: bill.discount_amount ? bill.discount_amount : 0
            }
        })

        fd.append('transactions', JSON.stringify(modifiedBills))
        return fd
    }

    return {formData, salesFormData,prepareSalesFormData, prepareFormData}
}
